$color-overseas: #cf024f;

.card {
  border: 0;
  border-top: 2px solid;
  border-radius: 0;
  border-bottom-right-radius: $border-radius;
  padding: 1.25rem;
  text-decoration: none;
  @include media-breakpoint-up(md) {
    transition: $transition-base;
    &:hover {
      box-shadow: $box-shadow-card;
    }
  }
  &.border-success {
    h2 {
      font-size: rem(17);
    }
  }
  &.card-expats {
    border: 1px solid #dee6e8;
    border-radius: 0;
    padding: 0;
    font-family: 'Poppins', Impact, $font-family-base;
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      padding: 1.5rem;
      @include media-breakpoint-up(md) {
        align-items: flex-end;
        margin-left: -2rem;
        padding-left: 0;
        padding-bottom: 1rem;
      }
      .card-text {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        color: #0b3444;
        @include media-breakpoint-up(md) {
          margin-bottom: .5rem;
        }
        @include media-breakpoint-up(xl) {
          flex-direction: row;
        }
        h2 {
          color: $color-overseas;
          font-weight: bold;
          font-size: rem(24);
          line-height: rem(30);
          border-bottom: 1px solid $color-overseas;
          padding-bottom: rem(18);
          margin-bottom: rem(18);
          @include media-breakpoint-up(xl) {
            border-bottom: 0;
            border-right: 1px solid $color-overseas;
            padding-bottom: rem(12);
            padding-right: rem(18);
            margin-right: rem(18);
          }
        }
      }
    }
  }
}