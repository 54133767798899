.error-page{

  .grid{
    display:flex;
    flex-direction:column;
    gap:4rem;
    @include media-breakpoint-up(lg){
      display:grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: repeat(3, auto);
    }

    .grid__cell{
      display:flex;
      flex-direction:column;
      gap:1rem;
      @include media-breakpoint-up(lg){
        grid-row: span 3;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: subgrid;
      }
    }
  }

}